/* General Styles */
body {
  background-color: #ffe858; /* Matched yellow */
  font-family: "Comic Sans MS", sans-serif;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

header {
  text-align: center;
  margin-top: 20px;
  color: #000;
  position: relative;
}

h1 {
  font-size: 2.5rem;
  font-weight: bold;
  transition: transform 0.2s ease-in-out;
}

h1:hover {
  transform: scale(1.1); /* Bounce effect on hover */
}

/* Fun Mode Button */
.fun-mode-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #ff4d4d; /* Red button */
  color: #fff;
  font-size: 1rem;
  font-weight: bold;
  padding: 10px 20px;
  border: none;
  border-radius: 15px;
  cursor: pointer;
  transition: transform 0.2s, box-shadow 0.2s;
}

.fun-mode-button:hover {
  background-color: #ff3333; /* Darker red on hover */
}

.upload-section {
  margin: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.options {
  display: flex;
  gap: 10px;
  margin-top: 15px;
}

.preview {
  margin: 20px auto; /* Centered with fixed margin */
  background: #fff;
  border: 4px solid #000;
  border-radius: 10px;
  padding: 10px; /* Consistent padding */
  width: fit-content; /* Prevent container from stretching */
  position: relative; /* Ensure it stays above any background effects */
  z-index: 2; /* Keeps it above rain effect */
}

canvas {
  max-width: 100%;
  border-radius: 10px;
}

/* Button Styles */
.custom-button {
  background-color: #fcd34d;
  color: #000;
  font-size: 1rem;
  font-weight: bold;
  padding: 12px 20px;
  border: 2px solid #000;
  border-radius: 15px;
  cursor: pointer;
  transition: transform 0.2s, box-shadow 0.2s;
}

.custom-button:active {
  transform: scale(0.95);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.custom-button:hover {
  background-color: #fee440;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.download-btn {
  display: block; /* Makes the button behave like a block element */
  margin: 20px auto; /* Centers the button horizontally */
  background-color: #fcd34d;
  color: #000;
  font-size: 1rem;
  font-weight: bold;
  padding: 12px 20px;
  border: 2px solid #000;
  border-radius: 15px;
  cursor: pointer;
  transition: transform 0.2s, box-shadow 0.2s;
}

.download-btn:active {
  transform: scale(0.95);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.download-btn:hover {
  background-color: #fee440;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}


/* Rain Effect */
#rain-container {
  position: fixed; /* Ensure it covers the screen but doesn't affect layout */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none; /* Prevent interactions with it */
  z-index: 1; /* Keep it behind other content */
}

.rain-drop {
  position: absolute;
  width: 50px;
  height: 50px;
  animation: fall 5s linear infinite;
}

@keyframes fall {
  0% {
    transform: translateY(-100px) rotate(0deg);
  }
  100% {
    transform: translateY(110vh) rotate(360deg);
  }
}

/* Footer Styles */
footer {
  margin-top: 40px;
  text-align: center; /* Center-aligns all content inside the footer */
  color: #000;
  font-size: 1rem;
}

footer img {
  margin-top: 10px;
  width: 100px; /* Adjust size as needed */
  height: auto;
  display: block; /* Ensures the image behaves like a block element */
  margin-left: auto; /* Centers horizontally when combined with */
  margin-right: auto; /* margin-left */
  animation: bounce 2s infinite ease-in-out; /* Bouncing animation */
}

@keyframes bounce {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-15px);
  }
}
/* Twitter Button */
.twitter-button {
  background-color: #1DA1F2; /* Twitter Blue */
  color: #fff;
  font-weight: bold;
  padding: 12px 20px;
  border: none;
  border-radius: 15px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.twitter-button:hover {
  background-color: #0d95e8; /* Darker Twitter Blue */
}

/* Footer Social Link */
footer .social-link {
  display: block;
  margin-top: 10px;
  font-size: 1.2rem;
  color: #000000;
  text-decoration: none;
  font-weight: bold;
}

footer .social-link:hover {
  text-decoration: underline;
}
